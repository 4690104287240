import types from './actionTypes'

const initialState = false

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MAIN_PAGE_IS_LOADING:
      return action.payload
    default:
      return state
  }
}

export default reducer