import React from 'react'
import { Collapse } from 'antd'

const { Panel } = Collapse

const SdkReleaseNotes = () => {

  return (
    <article>
      <h2 className="caption-primary">SDK Release Notes</h2>
      <Collapse 
        className="accordion"
        expandIconPosition="right"
      >
        <Panel header="1.4.3" key="1">
          <div className="data">December 2, 2019</div>
          <section>
            <h5>General changes</h5>
            <ul>
              <li>
                VPS localization process uses 6 frames at an angle of 40
                yaw degrees from last capture in Portrait mode and 5
                frames and 50 yaw degrees in Landscape mode
              </li>
              <li>
                Restriction of 15m for relocalization removed
              </li>
              <li>
                SampleManager looks for Base Providers instead of
                Sample Providers
              </li>
              <li>
                Temporary RenderTexture cleared from memory during
                GetCurrentFrame call on ArCoreVideoProvider and
                ArKitVideoProvider
              </li>
            </ul>
          </section>
          <section>
            <h5>Bug Fixes</h5>
            <ul>
              <li>
                SturfeeXRLightController orientation changed by 180 degrees
              </li>
            </ul>
          </section>
        </Panel>
        <Panel header="1.4.2" key="2">
          <div className="data">December 2, 2019</div>
          <section>
            <h5>General changes</h5>
            <ul>
              <li>
                VPS localization process uses 6 frames at an angle of 40
                yaw degrees from last capture in Portrait mode and 5
                frames and 50 yaw degrees in Landscape mode
              </li>
              <li>
                Restriction of 15m for relocalization removed
              </li>
              <li>
                SampleManager looks for Base Providers instead of
                Sample Providers
              </li>
              <li>
                Temporary RenderTexture cleared from memory during
                GetCurrentFrame call on ArCoreVideoProvider and
                ArKitVideoProvider
              </li>
            </ul>
          </section>
          <section>
            <h5>Bug Fixes</h5>
            <ul>
              <li>
                SturfeeXRLightController orientation changed by 180 degrees
              </li>
            </ul>
          </section>
        </Panel>
      </Collapse>
    </article>
  )
}

export default SdkReleaseNotes