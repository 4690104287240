import React from 'react'
import { Link } from 'react-router-dom'

const Welcome = () => {

  return (
    <section className="welcome">
      <article>
        <h2 className="caption-primary">Welcome to the Sturfee Developer Portal</h2>
      </article>

      <article>
        <h2 className="caption-primary">Key Features</h2>
      </article>

      <article>
        <h2 className="caption-primary">Coverage Map</h2>
      </article>

      <article>
        <h2 className="caption-primary">About the SDK</h2>
        <section>
          <h3 className="caption-secondary">Localization and Geometry Detection</h3>
          <p>
            Sturfee has developed the world's first city-scale Visual Positioning 
            Service (VPS) using cutting edge advancements in satellite imaging, 
            deep learning, geometrical computer vision, and big data to provide on
            the-ground localization and environmental awareness to camers based on
            what's seen from the sky. Using just a few pictures from your camera 
            device, we are able to determine:
          </p>
          <pre>
            <code>
              <ul>
                <li>accurate user location</li>
                <li>user's orientation and gaze target</li>
                <li>3D geometry of the local surroundings (planar surfaces and meshes)</li>
              </ul>
            </code>
          </pre>
        </section>
        <section>
          <h3 className="caption-secondary">Localization Flow</h3>
          <p>
            VPS localization follows roughly the same flow for each using the 
            Sturfee-VPS SDK.
          </p>
          <ul>
            <li>
              Initialize the XR Session using the <Link className="link-primary" to="">XRSessionManager</Link> (Turns on the
              device camera and sensors)
            </li>
            <li>
              Point the camera at the surroundings (take image(s))
            </li>
            <li>
              Ask the SDK to localize (send image(s) to the server)
            </li>
            <li>
              Wait for the localization to complete (server response; usually
              within 1-2 seconds)
            </li>
            <li>
              Initiate the AR experience (use your imagination!)
            </li>
          </ul>
        </section>
        
        
      </article>
    </section>
  )
}

export default Welcome