export const getTokenName = state => state.createTokenForm.tokenName
export const getRegion = state => state.createTokenForm.region
export const getIsMobileMenuOpen = state => state.isMobileMenuOpen
export const getIsAuth = state => state.isAuthenticated
export const getMainPageIsLoading = state => state.mainPageIsLoading

/* Email for password restoration */
export const getEmailForResetPassword = state => state.passwordResetForm.email

/* Email fot verification */
export const getEmailVarification = state => state.verificationEmail

/* User information */
export const getUserFullname = state => state.user.fullname
export const getUsername = state => state.user.username
export const getUserEmail = state => state.user.email
export const getUserOrganization = state => state.user.organization
export const getUserUuid = state => state.user.uuid

/* Tokens */
export const getTokens = state => state.tokens.items
export const getEditingTokenKey = state => state.tokens.editingTokenKey
export const getToDelete = state => state.tokens.toDelete
export const getToEdit = state => state.tokens.toEdit
export const getTokensIsLoading = state => state.tokens.isLoading