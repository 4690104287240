import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { 
  toDelete, 
  deleteToken, 
  setEditingTokenKey
} from '../'

import { Button, message } from 'antd'

const DeleteActions = ({ tokenId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const handleClick = () => {
    setIsLoading(true)

    dispatch(deleteToken(tokenId))
      .then(() => {
        message.success('Token has been deleted successfully', 5)

        dispatch(setEditingTokenKey(''))
        dispatch(toDelete(false))
      })
    
  }

  const handleCancel = () => {
    dispatch(toDelete(false))
    dispatch(setEditingTokenKey(''))
  }

  return (
    <div>
      <div>Are you sure?</div>
      <div>
        <Button
          onClick={ handleClick }
          loading={ isLoading }
          type="link"
          style={{
            marginRight: '20px'
          }}
          danger
        >
          Ok
        </Button>
        <Button
          onClick={ handleCancel }
          type="link"
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default DeleteActions