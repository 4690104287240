import types from './actionTypes'

const initialState = {
  items: [],
  editingTokenKey: '',
  toDelete: false,
  toEdit: false,
  isLoading: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TOKENS:
      return {
        ...state,
        items: action.payload
      }
    case types.SET_EDITING_TOKEN_KEY:
      return {
        ...state,
        editingTokenKey: action.payload
      }
    case types.TO_DELETE:
      return {
        ...state,
        toDelete: action.payload
      }
    case types.TO_EDIT:
      return {
        ...state,
        toEdit: action.payload
      }
    case types.TOKENS_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    default:
      return state
  }
}

export default reducer