import React from 'react'

import SdkDownload from './SdkDownload'
import GenerateToken from './GenerateToken'
import SdkReleaseNotes from './SdkReleaseNotes'

const Sdk = () => {

  return (
    <section className="sdk">
      <SdkDownload />
      <GenerateToken />
      <SdkReleaseNotes />
    </section>
  )
}

export default Sdk