import React from 'react'
import { useSelector } from 'react-redux'

import { getToEdit } from '../../../selectors'

import { Form, Input } from 'antd'

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const toEdit = useSelector(getToEdit)

  return (
    <td {...restProps}>
      {editing && toEdit ? (
        <Form.Item
          name={ dataIndex }
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

export default EditableCell