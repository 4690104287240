import React from 'react'
import { Link } from 'react-router-dom'

import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

const SdkDownload = () => {

  return (
    <article>
      <h2 className="caption-primary">SDK Download</h2>
      <p>
        Please the <Link to="/quick-start" className="link-primary">Quick Start
        </Link> guide for installation instructions.
      </p>
      <p>
        Current SDK version: 1.4.3
      </p>
      <Button
        type="primary" 
        shape="round" 
        icon={ <DownloadOutlined /> } 
        className="button-primary"
      >
        Download Unity SDK
      </Button>
    </article>
  )
}

export default SdkDownload